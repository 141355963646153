<template>
  <div class="loading-view">
    <div class="hero-loading">
      <div class="loading-lines">
        <div class="loading-line subtitle-line" :style="{ width: '110px' }">
          <div class="animated"></div>
        </div>
        <div class="loading-line title-line">
          <div class="animated"></div>
        </div>
        <div class="loading-line subtitle-line" :style="{ width: '192px' }">
          <div class="animated"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.loading-view {
  .loading-lines {
    overflow: hidden;
    .title-line {
      height: 35px;
      background-color: rgba(255, 255, 255, 0.05);
      margin: 20px 0;
      max-width: 526px;
      width: 100%;
    }
    .subtitle-line {
      height: 20px;
      background-color: rgba(255, 255, 255, 0.05);
    }
    .loading-line {
      position: relative;
      overflow: hidden;
      .animated {
        -webkit-animation: loading-line 2s infinite;
        animation: loading-line 2s infinite;
        content: " ";
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(
          110deg,
          rgba(0, 0, 0, 0),
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.15) 70%,
          rgba(255, 255, 255, 0.05),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }
  .hero-loading {
    background: #121212;
    height: 380px;
    width: 100%;
    padding: 40px 38px 33px 38px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 499px) {
  .loading-view {
    .hero-loading {
      padding: 40px 20px;
    }
  }
}
</style>