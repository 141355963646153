<template>
  <div
    class="team-card cursor-pointer"
    :class="[
      rowMode ? 'row-mode' : '',
      `avatar-is-${avatarSize}`,
      { 'has-controls': hasControls },
    ]"
    @click="$emit('click', item)"
  >
    <div class="bg-card-shadow">
      <img
        class="card-background"
        v-if="!rowMode"
        :src="item.backgroundImage || TeamCardBG"
        @error="fallbackBackground"
      />
      <div class="team-card-content">
        <div class="team-card-avatar" @click="openTeamView">
          <team-avatar
            class="team-card-icon"
            :src="item.avatar"
            :size="avatarSize"
          />
        </div>
        <div class="team-card-common-info" ref="info" @click="openTeamView">
          <div class="team-card-title">{{ item.name }}</div>
          <div class="team-card-info">
            <div class="team-info">
              <span class="team-city" v-if="item.location">
                {{ item.location }}
              </span>
              <span class="team-members">
                {{ item.membersCount }} {{ $t("members") }}
              </span>
            </div>
          </div>
          <div class="team-card-controls-mobile" v-if="hasControls">
            <slot name="controls"></slot>
          </div>
        </div>
        <div class="team-card-controls" v-if="hasControls">
          <slot name="controls"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamCardBG from "@/assets/team-card-bg.png";
import TeamAvatar from "@/components/TeamAvatar";

export default {
  name: "TeamCard",
  components: { TeamAvatar },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    rowMode: {
      type: Boolean,
      default: false,
    },
    avatarSize: {
      type: Number,
      default: 68,
    },
    disableRoute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TeamAvatar,
      TeamCardBG,
    };
  },
  computed: {
    hasControls() {
      return !!this.$slots && !!this.$slots.controls;
    },
  },
  methods: {
    fallbackAvatar(e) {
      if (e.target && e.target.src) {
        e.target.src = TeamAvatar;
      }
    },
    fallbackBackground(e) {
      if (e.target && e.target.src) {
        e.target.src = TeamCardBG;
      }
    },
    openTeamView() {
      if (this.disableRoute) return;

      this.$router.push({
        name: "teamLeaderboard",
        params: { id: this.item.slug },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.team-card {
  height: 122px;
  max-width: 100%;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.5s;
  overflow: hidden;

  .bg-card-shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(000, 000, 000, 0.4);
    position: relative;

    .card-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .team-card-content {
      display: flex;
      flex: 1 0;
      align-items: center;
      height: 100%;

      .team-card-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-left: 24px;
        cursor: pointer;
        .team-card-icon {
          overflow: hidden;
          border: 2px solid white;
        }
      }

      .team-card-common-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 0;
        height: 100%;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 24px;
        cursor: pointer;

        .team-card-title,
        .team-card-info {
          display: inline;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .team-card-title {
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
        }

        .team-card-info {
          font-size: 14px;

          .team-city + .team-members {
            &:before {
              content: "";
              display: inline-block;
              width: 3px;
              height: 3px;
              background-color: #ffffff;
              border-radius: 50%;
              vertical-align: middle;
              margin-left: 0.3rem;
              margin-right: 0.3rem;
            }
          }

          @media screen and (max-width: 499px) {
            .team-info {
              display: flex;
              flex-direction: column;

              .team-city + .team-members {
                &:before {
                  display: none;
                }
              }
            }
          }
        }

        .team-card-controls-mobile {
          display: none;
          margin-top: 12px;
        }

        & + .team-card-controls {
          padding-left: 15px;
        }
      }

      .team-card-controls {
        display: flex;
        align-items: center;
        justify-content: center;

        .button-join,
        .button-invite {
          height: 36px;
          font-size: 12px;
          padding: 0 28px;
        }
      }
    }
  }

  &:hover:not(.row-mode) {
    transform: scale(1.03);
    @media screen and (max-width: 499px) {
      transform: scale(1);
    }
  }

  & + .team-card {
    margin-top: 8px;
  }

  &.row-mode {
    height: 105px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    .bg-card-shadow {
      background-color: transparent;

      .team-card-content {
        .team-card-avatar {
          padding-left: 3px;

          @media screen and (min-width: 376px) and (max-width: 499px) {
            padding-left: 9px;
          }
        }
      }
    }

    & + .team-card {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 376px) and (max-width: 499px) {
    .bg-card-shadow {
      .team-card-content {
        .team-card-common-info {
          .team-card-title {
            font-size: 18px;
            line-height: 21px;
          }
        }
      }
    }
    &.row-mode,
    &.has-controls {
      height: 132px;
      .bg-card-shadow {
        .team-card-content {
          flex-wrap: wrap;
          padding: 15px 0 25px;
          height: auto;
          align-items: flex-start;

          .team-card-common-info {
            padding-left: 35px;
            padding-right: 0;
            .team-card-title {
              font-size: 18px;
              line-height: 1.6;
            }
            .team-card-controls-mobile {
              display: flex;
              margin-top: 8px;
              ::v-deep(.button-join) {
                font-size: 12px;
                height: 36px;
                padding: 0 28px;
              }
              ::v-deep(.button-invite) {
                font-size: 12px;
                height: 36px;
                padding: 0 28px;
              }
            }
          }

          .team-card-controls {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .bg-card-shadow {
      .team-card-content {
        .team-card-common-info {
          .team-card-title {
            font-size: 18px;
            line-height: 21px;
          }
        }
      }
    }
    &.row-mode,
    &.has-controls {
      height: auto;
      .bg-card-shadow {
        .team-card-content {
          flex-wrap: wrap;
          padding: 15px 0 25px;
          height: auto;
          align-items: flex-start;

          .team-card-common-info {
            padding-left: 35px;
            padding-right: 0;
            .team-card-title {
              font-size: 18px;
              line-height: 1.6;
            }
            .team-card-controls-mobile {
              display: flex;
              margin-top: 8px;
              ::v-deep(.button-join) {
                font-size: 12px;
                height: 36px;
                padding: 0 28px;
              }
              ::v-deep(.button-invite) {
                font-size: 12px;
                height: 36px;
                padding: 0 28px;
              }
            }
          }

          .team-card-controls {
            display: none;
          }
        }
      }
    }
  }
}
</style>
