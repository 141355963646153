<template>
  <v-avatar class="team-avatar" :size="size" v-bind="$attrs" :class="{'is-default': isDefault}">
    <img :src="src || TeamDefaultAvatar" @error="fallbackAvatar" alt="" />
  </v-avatar>
</template>

<script>
import TeamDefaultAvatar from "@/assets/team-card-avatar.svg";

export default {
  name: "TeamAvatar",
  props: {
    src: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 68
    },
  },
  data() {
    return {
      TeamDefaultAvatar,
      isDefault: false
    }
  },
  methods: {
    fallbackAvatar(e) {
      if (e.target && e.target.src) {
        this.isDefault = true
        e.target.src = TeamDefaultAvatar;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.team-avatar {
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid white;
}
</style>
