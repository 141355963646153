<template>
  <div class="rating-levels">
    <div class="rating-level" v-if="level == '0'">
      <img src="../../../assets/rating_helmet_dark.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_dark.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_dark.svg" alt="lvl" />
    </div>
    <div class="rating-level" v-if="level == '1'">
      <img src="../../../assets/rating_helmet_red.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_dark.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_dark.svg" alt="lvl" />
    </div>
    <div class="rating-level" v-if="level == '2'">
      <img src="../../../assets/rating_helmet_red.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_red.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_dark.svg" alt="lvl" />
    </div>
    <div class="rating-level" v-if="level == '3'">
      <img src="../../../assets/rating_helmet_red.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_red.svg" alt="lvl" />
      <img src="../../../assets/rating_helmet_red.svg" alt="lvl" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["level"],
};
</script>
<style lang="scss" scoped>
.rating-levels {
  .rating-level {
    height: 30px;
    img {
      margin-right: 6px;
    }
  }
}
</style>