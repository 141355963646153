<template>
  <div class="question-line">
    <div
      class="question-header"
      :class="[showAnswer ? 'show-answer' : 'hide-answer']"
    >
      <div class="question-text">
        {{ question }}
      </div>
      <i
        class="material-icons"
        @click="onQuestionClick('open')"
        v-if="!showAnswer"
        >add</i
      >
      <i
        class="material-icons"
        @click="onQuestionClick('close')"
        v-if="showAnswer"
        >remove</i
      >
    </div>
    <div
      class="question-answer"
      :id="'answer-' + id"
      v-html="questionAnswer"
    ></div>
  </div>
</template>
<script>
import { marked } from "marked";

export default {
  data: () => ({
    showAnswer: false,
  }),
  props: {
    question: String,
    answer: String,
    id: Number,
  },
  computed: {
    questionAnswer() {
      return marked(this.answer);
    },
  },
  methods: {
    // clearText(text) {
    //   return text.replace(/<\/?[^>]+(>|$)/g, "");
    // },
    // transformText() {
    //   let el = document.getElementById(`answer-${this.id}`);
    //   el.innerHTML = this.question.answer;
    // },
    onQuestionClick(event) {
      let el = document.getElementById(`answer-${this.id}`);
      if (event === "open") {
        this.showAnswer = true;
        el.style.maxHeight = el.scrollHeight + 25 + "px";
      } else if (event === "close") {
        el.style.maxHeight = 0;
        setTimeout(() => {
          this.showAnswer = false;
        }, 200);
      }
    },
  },
  mounted() {
    // this.transformText();
  },
};
</script>

<style lang="scss" scoped>
.question-line {
  padding: 27px 23px 27px 25px;
  .question-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: relative;

    .question-text {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.0025em;
      text-transform: capitalize;
      padding-right: 20px;
    }
    i {
      position: absolute;
      right: 0;
      color: rgba(255, 255, 255, 0.4);
      @include cursorPointer;
    }
  }
  .question-answer {
    max-width: 415px;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }
  .show-answer {
    margin-bottom: 27px;
  }
  .hide-answer {
    margin-bottom: 0;
  }
}
</style>