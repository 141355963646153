<template>
  <div class="refund-confirm">
      <div class="close-icon" @click="close">
        <i class="material-icons"> close </i>
      </div>
      <h3 class="dialog-title">
        {{ $t('confirm_refund_title') }}
      </h3>
      <div class="dialog-body">
        <p>{{ $t('confirm_refund_desc_part_I') }}</p>
        <p>{{ $t('confirm_refund_desc_part_II') }}</p>
      </div>
      <div class="dialog-buttons">
        <base-button
          class="action-button"
          btn-type="tertiary-light"
          @click="back"
        >
          {{ $t('back') }}
        </base-button>
        <base-button
          class="action-button"
          btn-type="mem-primary"
          @click="submit"
        >
          {{ $t('submit') }}
        </base-button>
      </div>
  </div>
</template>
<script>
import BaseButton from "@/components/base/BaseButton";
import {mapActions} from "vuex";
export default {
  name: 'RefundConfirmModal',
  components: {BaseButton},
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    ...mapActions(['createZDTicket', 'handleToolbarNotification']),
    close() {
      this.$emit("close");
    },
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit");
      this.createZDTicket({
        tickets: this.items
      })
        .then(() => {
          this.handleToolbarNotification({
            type: "success",
            text: "refund_request_submitted",
            isVisible: true,
          });
        })
        .catch(() => {
          this.handleToolbarNotification({
            type: "warning",
            text: "server_error",
            isVisible: true,
          });
        })
      .finally(() => {
        setTimeout(() => {
          this.handleToolbarNotification({ isVisible: false });
        }, 3000);
      })
      this.close()
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-confirm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  position: relative;
  padding: 39px 35px 35px;
  background-color: #ffffff;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #000000;


  @media screen and (max-width: 499px) {
    padding: 24px 12px 20px 12px;
    border-radius: 5px;
  }
  .close-icon {
    position: absolute;
    top: 9px;
    right: 9px;
    cursor: pointer;

    &:hover,
    &:focus {
      &:after {
        opacity: 0;
      }
    }
  }

  .dialog-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }

  .dialog-body {
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #4F4F4F;

    p {
      margin: 0;

      & + p {
        margin-top: 1rem;
      }
    }
  }

  .dialog-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 34px;

    .action-button {
      width: 160px;
      height: 36px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      & + .action-button {
        margin-left: 12px;
      }
    }
  }
}
</style>
