import { render, staticRenderFns } from "./RefundConfirmModal.vue?vue&type=template&id=b8712060&scoped=true"
import script from "./RefundConfirmModal.vue?vue&type=script&lang=js"
export * from "./RefundConfirmModal.vue?vue&type=script&lang=js"
import style0 from "./RefundConfirmModal.vue?vue&type=style&index=0&id=b8712060&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8712060",
  null
  
)

export default component.exports