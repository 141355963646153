<template>
  <div class="dialog-content">
    <div class="close-button" @click="onClose">
      <i class="material-icons">close</i>
    </div>
    <div class="dialog-header">
      <h3 class="content-title">{{ $t('spartan_plus_ticket_protection') }}</h3>
    </div>
    <div class="dialog-body">
      <p class="content-info">{{ $t('ticket_protection_description') }}</p>
      <ul class="ticket-list" v-if="availableTickets.length">
        <li
          class="ticket-item"
          v-for="ticket in availableTickets"
          :key="ticket.id"
        >
          <input
            :id="`ticket-${ticket.id}`"
            class="ticket-checkbox"
            :checked="selectedTickets.includes(ticket.id)"
            type="checkbox"
            @change="onChange(ticket.id, $event)"
          />
          <label
            class="item-content"
            :for="`ticket-${ticket.id}`"
          >
            <div class="content-wrapper">
              <span class="ticket-description">{{ ticket.reg_choice_name }}</span>
              <span class="ticket-text">{{ ticket.full_name }}</span>
            </div>
          </label>
        </li>
      </ul>
    </div>
    <v-card-actions class="dialog-footer">
      <base-button
        btn-type="mem-primary"
        @click="submit"
        :disabled="!selectedTickets.length"
      >
        {{ $t('request_refund') }}
      </base-button>
    </v-card-actions>
  </div>
</template>

<script>
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "RefundTicketsModal",
  components: {
    BaseButton,
  },
  props: {
    event: {
      type: Object,
      default: () => {
      }
    },
    tickets: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTickets: []
    }
  },
  computed: {
    availableTickets() {
      if (!this.event || !this.event.tickets) return []
      return this.event.tickets
        .filter(item => item.is_purchased_by_me && item.can_rescheduled_refund)
        .map(item => ({
          ...item,
          full_name: item.is_claimed ? `${item.attendee?.first_name} ${item.attendee?.last_name.charAt(0).toUpperCase()}.` : 'Unclaimed',
        }))
    }
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
    submit() {
      this.$emit("submit", this.selectedTickets)
    },
    onChange(ticket_id, e) {
      const value = e.currentTarget.checked
      if (value) {
        this.selectedTickets.push(ticket_id)
      } else {
        const index = this.selectedTickets.indexOf(ticket_id)
        this.selectedTickets.splice(index, 1)
      }
    },
  },
  mounted() {
    if (this.tickets.length) {
      this.selectedTickets = this.tickets
    } else {
      this.selectedTickets = this.availableTickets.map(item => item.id)
    }
  }
}
</script>


<style lang="scss" scoped>
.dialog-content {
  position: relative;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #FFFFFF;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 24px 0 24px 35px;
  height: 100%;
  min-height: 366px;
  max-height: 550px;
  overflow-y: hidden;


  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000000;
    z-index: 5;
    display: flex;
    font-size: 30px;
    @include cursorPointer;

    i {
      font-size: inherit;
    }
  }

  .dialog-header {
    display: flex;
    position: relative;
    align-items: center;
    padding-top: 15px;

    .content-title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
  }

  .dialog-body {
    max-height: 355px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 35px;

    .content-info {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #4F4F4F;
      margin-top: 32px;
      margin-bottom: 24px;
    }

    .ticket-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      width: 100%;
      box-sizing: border-box;

      .ticket-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        width: 100%;
        box-sizing: border-box;

        .ticket-checkbox {
          display: none;

          & + label::before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #000;
            border-radius: 0.25em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            margin-right: 24px;
            box-sizing: border-box;
          }

          &:checked + label::before {
            border-color: #000;
            background-color: #000;
            background-image: url("../../assets/check-icon.svg");
            background-size: 14px;
            background-position: center center;
          }

          &:not(:disabled):not(:checked) + label:hover::before {
            border-color: #000;
          }

          &:not(:disabled):active + label::before {
            background-color: #000;
            border-color: #000;
          }

          &:focus + label::before {
            border-color: #000;
            border-width: 2px;
          }

          &:focus:not(:checked) + label::before {
            border-color: #000;
          }

          &:disabled + label::before {
            background-color: #e9ecef;
            border-color: #e9ecef;
          }
        }

        .item-content {
          display: flex;
          align-items: center;
          padding: 26px 0;
          flex: 1 0;
          cursor: pointer;

          .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .ticket-description {
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 120%;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              color: #4F4F4F;
            }

            .ticket-text {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.0025em;
              color: #000000;
              margin-top: 4px;
            }
          }
        }

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .ticket-notify {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
    }
  }

  .dialog-footer {
    padding-top: 24px;
    padding-bottom: 9px;
    padding-left: 0;

    button {
      height: 36px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding: 0 12px;
      color: #FFFFFF;
    }

  }


  @media screen and (max-width: 499px) {
    padding-left: 12px;

    .dialog-body {
      padding-right: 12px;

      .ticket-list {
        .ticket-item {
          .item-content {
            padding: 16px 0;
          }
        }
      }
    }

    .dialog-footer {
      padding-bottom: 14px;
    }
  }
}
</style>
