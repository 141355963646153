<template>
  <div class="event-view">
    <loading-view v-if="isLoading" />
    <transition name="slide-y-reverse-transition" mode="out-in">
      <div v-if="!isLoading">
        <div class="sticky-header" v-if="showStickyHeader">
          <div class="event-back-btn" @click="$router.push({ name: 'events' })">
            <i class="material-icons">keyboard_arrow_left</i>
            <span>{{ $t("back") }}</span>
          </div>
          <div>
            <mem-button
              :btnType="'secondary-dark'"
              class="event-btn"
              @click="openMap"
              v-if="showMap"
              >{{ $t("directions") }}</mem-button
            >
            <mem-button
              :btnType="'secondary-dark'"
              class="event-btn"
              @click="viewTickets"
              >{{ $t("view_tickets") }}</mem-button
            >
          </div>
        </div>

        <!-- hero section -->
        <hero-block
          :event="eventData"
          :showStickyHeader="showStickyHeader"
          :can-share="canShare"
          v-on:open-map="openMap"
          v-on:view-tickets="viewTickets"
          v-on:share="handleShareDialog"
        />

        <!-- US Army Banner -->
        <div class="army-promo" v-if="showArmyBanner">
          <banner-army @hide-banner="hideArmyBanner" />
        </div>

        <!-- tickets section -->
        <tickets-list :tickets="eventData.tickets" :event-data="eventData" />

        <!-- teams section -->
        <div class="event-teams" v-if="showTeams">
          <div class="event-subheader">
            {{ $t("your_teams") }}
          </div>

          <template>
            <div class="teams-list">
              <team-card
                v-for="(team, index) in assignedTeams"
                :key="index"
                :item="team"
                disable-route
              >
                <div slot="controls">
                  <mem-button
                    class="button-invite"
                    btn-type="secondary-dark"
                    @click="showInviteDialog(team)"
                  >
                    {{ $t("invite") }}
                  </mem-button>
                </div>
              </team-card>
            </div>
          </template>
        </div>

        <!-- shop section -->
        <div class="shop-section" v-if="showShopSection">
          <div class="section-header">
            <!-- showLimitedDeal -->
            <div class="event-subheader">
              <span v-if="showLimitedDeal">
                {{ $t("event_limited_shop_section_title") }}
              </span>
              <span v-else>{{ $t("event_shop_section_title") }}</span>
            </div>

            <span class="shop-timer" v-if="showLimitedDeal">
              {{ shopTimerFormatted }}
            </span>
          </div>
          <div class="shop-items">
            <product-carousel
              :items="eventData.event_upsell.products"
              :theme="'dark'"
              @on-item-click="pushDataLayerEvent('Merch Upsell Click', $event)"
            />
          </div>
        </div>

        <!-- promo -->
        <div class="event-promo" v-if="userData.spartan_country_code === 'US'">
          <div class="event-subheader">{{ $t("he_promo_section_title") }}</div>
          <promo-card-he />
        </div>

        <!-- rating secton -->
        <div class="event-ratings" v-if="showRates">
          <div class="ratings-name event-subheader">{{ $t("rating") }}</div>
          <div class="rating-level" v-if="eventData.rates.elevation_gain">
            <rating-level :level="eventData.rates.elevation_gain" />
            <div class="level-name">{{ $t("elevation_gain") }}</div>
          </div>
          <div class="rating-level" v-if="eventData.rates.altitude">
            <rating-level :level="eventData.rates.altitude" />
            <div class="level-name">{{ $t("altitude") }}</div>
          </div>
          <div class="rating-level" v-if="eventData.rates.terrain">
            <rating-level :level="eventData.rates.terrain" />
            <div class="level-name">{{ $t("terrain") }}</div>
          </div>
          <div class="rating-level" v-if="eventData.rates.weather">
            <rating-level :level="eventData.rates.weather" />
            <div class="level-name">{{ $t("weather") }}</div>
          </div>
        </div>

        <!-- Map section -->
        <div class="event-map" v-if="showMap">
          <div class="map-name event-subheader">{{ $t("location") }}</div>
          <mem-map :event="eventData" />
          <!-- <div id="map"></div> -->
          <div class="map-details">
            <div class="map-address">{{ eventData.address }}</div>
            <div>
              <mem-button
                :btnType="'tertiary-dark'"
                class="event-btn"
                @click="openMap"
                >{{ $t("directions") }}</mem-button
              >
            </div>
          </div>
        </div>

        <!-- FAQ section -->
        <div class="event-faq">
          <div class="faq-name event-subheader">{{ $t("faq") }}</div>
          <div
            v-for="(question, index) in eventData.faqs"
            v-bind:key="index"
            class="faq-item"
            :style="{ background: isEven(index) ? '#121212' : '#020000' }"
          >
            <question-line
              :question="question.title"
              :answer="question.description"
              :id="index"
            />
          </div>
        </div>
      </div>
    </transition>
    <v-dialog
      v-model="inviteDialog"
      overlay-color="#000000"
      overlay-opacity="0.54"
      :max-width="480"
      class="mem-dialog"
    >
      <invite-team-dialog
        v-if="inviteDialog"
        :source="'EventPage'"
        :inviteLink="inviteTeamLink"
        :teamTickets="inviteTeamTickets"
        :eventId="eventData.ts_id"
        :eventCountry="eventData.ts_org"
        @close-invite="inviteDialog = false"
      />
    </v-dialog>

    <!-- share modal -->
    <v-dialog
      v-model="shareDialog"
      class="mem-dialog"
      overlay-color="#000000"
      overlay-opacity="0.54"
      fullscreen
    >
      <share-dialog
        :key="shareDialogKey"
        :event-name="eventData.name"
        :start-date="eventData.start_date"
        v-on:close="handleShareDialog"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { uniqBy, map, filter, groupBy, findIndex } from "lodash";
import { KeyConverter } from "@/common/helpers";
import moment from "moment";

import RatingLevel from "./components/RatingLevel.vue";
import QuestionLine from "./components/QuestionLine.vue";
import HeroBlock from "./components/HeroBlock.vue";
import LoadingView from "./components/LoadingView.vue";
import TeamCard from "@/components/TeamCard";
import BaseButton from "@/components/base/BaseButton";
import InviteTeamDialog from "@/views/Teams/components/InviteTeamDialog";

export default {
  name: "EventView",
  data: () => ({
    marker: "",
    eventAddress: "",
    heroHeader: "",
    sticky: 45,
    stickyNext: 312,
    showStickyHeader: false,
    isLoading: true,
    inviteDialog: false,
    shareDialog: false,
    shareDialogKey: 0,
    inviteTeamLink: "",
    inviteTeamTickets: [],
    shopTimer: 0,
    shopTimerInterval: "",
    showArmyBanner: false,
  }),
  components: {
    InviteTeamDialog,
    RatingLevel,
    QuestionLine,
    HeroBlock,
    LoadingView,
    TeamCard,
    "mem-button": BaseButton,
    "tickets-list": () => import("./components/TicketsList.vue"),
    "share-dialog": () => import("./components/DialogShareEvent.vue"),
    "mem-map": () => import("@/components/TheMap.vue"),
    "product-carousel": () => import("@/components/shop/ProductCarousel.vue"),
    "promo-card-he": () => import("@/components/banners/BannerHotelEngine.vue"),
    "banner-army": () => import("@/components/banners/BannerArmy.vue"),
  },
  computed: {
    ...mapGetters(["eventData", "userData", "specificLinks"]),
    tickets() {
      return this.eventData.tickets;
    },
    assignedTeams() {
      // filter -> get all tickets with team
      // map -> get only team object
      // uniqBy -> get only uniq teams
      let uniqTeams = uniqBy(
        map(
          filter(this.tickets, (ticket) => ticket.team && ticket.team.joined),
          "team"
        ),
        "id"
      );

      return map(uniqTeams, (team) => {
        // TODO: this is suck
        let newTeam = KeyConverter.camelize(team);
        newTeam.tickets = this.teamRegChoices[team.id];
        return newTeam;
      });
    },
    teamRegChoices() {
      // reg_choice_name
      return groupBy(filter(this.tickets, "team"), "team.id");
    },
    showShopSection() {
      let { event_upsell } = this.eventData;
      if (!event_upsell) return;
      return event_upsell.products.length > 0;
      // return this.shopTimer > 0;
    },
    showLimitedDeal() {
      return this.shopTimer > 0;
    },
    showTeams() {
      return this.eventData && this.assignedTeams.length !== 0;
    },
    showMap() {
      let { address, latitude, longitude } = this.eventData;
      return address || (latitude && longitude);
    },
    showRates() {
      let { rates } = this.eventData;
      if (!rates) return;
      return (
        rates.elevation_gain || rates.altitude || rates.terrain || rates.weather
      );
    },
    canShare() {
      if (!this.isMobile) return;
      if (!navigator.share) return;

      let { tickets, ts_org } = this.eventData;
      let kidTicketIndex = findIndex(tickets, "is_kids");

      if (kidTicketIndex !== -1 || ts_org !== "US") return;

      return true;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    shopTimerFormatted() {
      // const timeZero = moment("1900-01-01 00:00:00");
      // return `${this.$t("event_shop_section_timer")} ${timeZero
      //   .add(this.shopTimer, "seconds")
      //   .format("mm[m] ss[s]")}`;
      let minutes = ~~(this.shopTimer / 60); // ~~ is a shorthand for Math.floor
      let seconds = this.shopTimer - minutes * 60;
      return `${this.$t("event_shop_section_timer")} ${minutes}m ${seconds}s`;
    },
    eventDate() {
      return moment(this.eventData.start_date).format("MMMM D");
    },
    bannerTarget() {
      if (this.userData.spartan_country_code !== "US") return false;
      if (!this.userData.birth_date) return false;

      let userAge = moment().diff(moment(this.userData.birth_date), "year");
      return userAge >= 16 && userAge <= 28;
    },
  },
  methods: {
    ...mapActions(["getEvent"]),
    isEven(index) {
      return index % 2 === 0;
    },
    openMap() {
      if (this.eventData?.address)
        return window.open(
          `https://maps.google.com/?q=${this.eventData.address}`
        );
      return window.open(
        `https://www.google.com/maps/search/?api=1&query=${this.eventData.latitude},${this.eventData.longitude}`
      );
    },
    handleShareDialog() {
      if (!this.shareDialog) {
        this.shareDialogKey += 1;
        this.shareDialog = true;
      } else {
        this.shareDialog = false;
      }
    },
    viewTickets() {
      this.$router.push({
        name: `eventTickets`,
        params: { id: this.$route.params.id },
      });
    },
    scrollPage() {
      if (!document.getElementById("hero-header") || window.innerWidth <= 499)
        return;
      else {
        if (!this.heroHeader)
          this.heroHeader = document.getElementById("hero-header");
        if (window.pageYOffset >= this.sticky) {
          this.heroHeader.classList.add("shadow");
        } else {
          this.heroHeader.classList.remove("shadow");
        }
        if (window.pageYOffset >= this.stickyNext) {
          this.showStickyHeader = true;
        } else {
          this.showStickyHeader = false;
        }
      }
    },
    showInviteDialog(listTeam) {
      this.inviteTeamLink = `Hey, I invite you to join my team ${listTeam.name} at ${this.eventData.name} on ${this.eventDate}.\nBuy the ticket using the link below and join my team after the purchase.\n`;

      // let eventId = this.$route.params.id;
      // this.inviteTeamLink = `${window.location.origin}/join-race?event_id=${eventId}&team_id=${listTeam.id}`;
      this.inviteTeamTickets = listTeam.tickets;

      // if (!listTeam.isPublic && listTeam.inviteCode) {
      //   this.inviteTeamLink =
      //     this.inviteTeamLink + `&invite_code=${listTeam.inviteCode}`;
      // }
      this.inviteDialog = true;
    },
    runShopTimer(duration) {
      if (!duration) return;
      if (this.shopTimerInterval) clearInterval(this.shopTimerInterval);

      this.shopTimer = duration;
      console.log("Start timer. Duration: ", duration);

      // if (!this.shopTimer) return clearInterval(this.shopTimerInterval);

      this.shopTimerInterval = setInterval(() => {
        if (this.shopTimer === 0) return clearInterval(this.shopTimerInterval);
        this.shopTimer--;
      }, 1000);
    },
    pushDataLayerEvent(event, data) {
      if (!window.dataLayer) return;

      let payload = {
        event,
        eventId: this.eventData.id,
        eventName: this.eventData.name,
        eventStartDate: this.eventData.start_date,
      };
      if (data) payload.productId = data.id;

      return window.dataLayer.push(payload);
    },
    hideArmyBanner() {
      console.log("Hide banner");
      localStorage.setItem("hideArmyPromoBanner", true);
      this.showArmyBanner = false;
    },
  },
  async mounted() {
    try {
      await this.getEvent(this.$route.params.id);
      if (this.bannerTarget && !localStorage.getItem("hideArmyPromoBanner")) {
        this.showArmyBanner = true;
      }
      // shop section
      let { expires } = this.eventData.event_upsell || {};
      this.runShopTimer(expires);

      // share modal
      let { showShareModal } = this.$route.params;
      if (showShareModal && this.canShare) this.handleShareDialog();
    } catch (error) {
      console.log(error, "");
    }
    this.isLoading = false;
  },
  watch: {
    showShopSection: {
      handler(val) {
        if (val) this.pushDataLayerEvent("Merch Upsell Visible");
      },
    },
    showLimitedDeal: {
      handler(status, oldStatus) {
        if (!oldStatus) return;

        this.$nextTick(async () => {
          await this.getEvent(this.$route.params.id);
        });
      },
    },
  },
  created() {
    window.addEventListener("scroll", this.scrollPage, { passive: true });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage, { passive: true });
    clearInterval(this.shopTimerInterval);
  },
};
</script>
<style lang="scss" scoped>
.ticket-menu {
  height: 240px;
  width: 278px;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #000000;
  /* Grey 3 */
  border: 0.5px solid #4f4f4f;
  box-sizing: border-box;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.5);
  border-radius: 18px;

  padding: 12px;

  .menu-item {
    height: 55px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 0 0 15px;

    transform: perspective(1px) translateZ(0);
    transition-duration: 0.5s;

    i {
      color: rgba(255, 255, 255, 0.4);
      margin-right: 18px;
    }
  }

  .menu-item:hover {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1.03);

    i {
      color: #ffffff;
      transition: color 0.5s linear;
    }

    // transition: background-color 0.2s linear;
  }
}

.ticket-mobile-dialog {
  background: #ffffff;
  border-radius: 5px;
  padding: 25px 0 25px 25px;

  color: #000000;

  .dialog-menu-item {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      color: #c0c0c0;
      margin-right: 14px;
    }
  }
}

.event-view {
  max-width: min(670px, 100vw);
  width: 100%;
  .event-subheader {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    @media screen and (max-width: 499px) {
    }
    @media screen and (max-width: 375px) {
      font-size: 22px;
    }
  }

  .event-btn {
    height: 36px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    padding: 0 20px;
  }

  .sticky-header {
    background: #000000;
    position: sticky;
    top: 65px;
    height: 85px;
    z-index: $zindex-page-sticky-el;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    @media screen and (max-width: 499px) {
      display: none;
    }
    .event-back-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      @include cursorPointer;
      i {
        position: absolute;
        font-size: 30px;
        left: -10px;
      }
      span {
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .event-btn {
      height: 35px;
    }
    .event-btn:first-child {
      margin-right: 12px;
    }
  }
  .army-promo {
    margin-top: 12px;
    @media screen and (max-width: $mobile) {
      margin-top: 16px;
    }
  }
  .event-promo {
    @include elementMarginBottom("M");
    .event-subheader {
      @include elementMarginBottom("S");
      border-top: 1px solid #333333;
      padding-top: 14px;
      @media screen and (max-width: $mobile) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  .shop-section {
    @include elementMarginBottom("M");
    @media screen and (max-width: $mobile) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .section-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 14px;
      padding-bottom: 20px;
      border-top: 1px solid #333333;
      .shop-timer {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 0.0025em;
        text-transform: capitalize;
        color: #000000;
        background-color: #2dff4e;
        padding: 0 4px;
        margin-top: 10px;
      }
    }
    .shop-items {
      min-height: 378px;
    }
  }

  .event-tickets {
    padding: 50px 0;
    @media screen and (max-width: 375px) {
      padding: 37px 0 50px 0;
    }
  }

  .event-teams {
    margin-bottom: 72px;
    .event-subheader {
      margin-bottom: 20px;
      @media screen and (max-width: 499px) {
        padding: 0 16px;
      }
    }
    .teams-list {
      .button-invite {
        margin-right: 24px;
      }
    }
  }

  .event-ratings {
    width: 325px;
    padding-bottom: 50px;
    @media screen and (max-width: 499px) {
      width: 100%;
      padding: 0 20px 36px 20px;
    }
    @media screen and (max-width: 375px) {
      padding: 0 16px 36px 16px;
    }
    .ratings-name {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px 0 30px 0;
      @media screen and (max-width: 499px) {
        padding: 16px 0 24px 0;
      }
    }
    .rating-level {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 14px;
      .level-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-transform: capitalize;
        margin-left: 20px;
      }
    }
  }

  .event-map {
    @media screen and (max-width: 499px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 375px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .map-name {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px 0 24px 0;
      @media screen and (max-width: 499px) {
        padding: 16px 0 24px 0;
      }
    }
    #map {
      height: 240px;
      max-width: 670px;
      width: 100%;
      @media screen and (max-width: 375px) {
        height: 226px;
      }
    }
    .map-details {
      padding: 30px 0 53px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .map-address {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.0025em;
        text-transform: capitalize;
        max-width: 220px;
      }
    }
  }

  .event-faq {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 100px;
    @media screen and (max-width: 499px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media screen and (max-width: 375px) {
      padding-left: 0;
      padding-right: 0;
    }
    .faq-name {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px 0 30px 0;
      @media screen and (max-width: 499px) {
        padding: 16px 0 24px 0;
      }
      @media screen and (max-width: 375px) {
        margin: 0 16px;
      }
    }
  }
}
</style>
